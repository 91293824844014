@tailwind base;
@tailwind components;
@tailwind utilities;

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

*:focus {
  outline: none;
}

@font-face {
  font-family: 'Matter';
  src: local('Matter'), url(./fonts/Matter/Matter-Light.otf) format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Matter';
  src: local('Matter'), url(./fonts/Matter/Matter-LightItalic.otf) format('opentype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Matter';
  src: local('Matter'), url(./fonts/Matter/Matter-Regular.otf) format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Matter';
  src: local('Matter'), url(./fonts/Matter/Matter-RegularItalic.otf) format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Matter';
  src: local('Matter'), url(./fonts/Matter/Matter-Medium.otf) format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Matter';
  src: local('Matter'), url(./fonts/Matter/Matter-MediumItalic.otf) format('opentype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Matter';
  src: local('Matter'), url(./fonts/Matter/Matter-SemiBold.otf) format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Matter';
  src: local('Matter'), url(./fonts/Matter/Matter-SemiBoldItalic.otf) format('opentype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Matter';
  src: local('Matter'), url(./fonts/Matter/Matter-Bold.otf) format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Matter';
  src: local('Matter'), url(./fonts/Matter/Matter-BoldItalic.otf) format('opentype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Matter';
  src: local('Matter'), url(./fonts/Matter/Matter-Heavy.otf) format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Matter';
  src: local('Matter'), url(./fonts/Matter/Matter-HeavyItalic.otf) format('opentype');
  font-weight: 900;
  font-style: italic;
}

html,
body {
  font-family: 'Matter';
  margin: 0;
  background-color: #171717;
  color: #fff;
}

#root {
  height: 100%;
}

.grecaptcha-badge {
  visibility: hidden;
}
